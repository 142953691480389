const { CLASS_IS_DISABLED } = await import("@/util/layout");
const { getElementBySelector } = await import("@/util/elements");
const { default: Swiper, Navigation, Scrollbar, Thumbs } = await import("swiper");

Swiper.use([Navigation, Scrollbar, Thumbs]);

export const MODAL_ID_PREFIX = "gallery-";

export const DATA_PROP_IMAGE_GALLERY_TRIGGER = "imageGalleryTrigger";
export const DATA_ATTR_IMAGE_GALLERY_ITEM = "data-image-gallery-item";

const DATA_SWIPER = "data-swiper";
export const DATA_ATTR_SWIPER_CONTAINER = DATA_SWIPER + "-container";
export const DATA_ATTR_SWIPER_NAV = DATA_SWIPER + "-nav";
export const DATA_ATTR_SWIPER_NAV_NEXT = DATA_ATTR_SWIPER_NAV + "-next";
export const DATA_ATTR_SWIPER_NAV_PREV = DATA_ATTR_SWIPER_NAV + "-prev";
export const DATA_ATTR_SWIPER_SCROLLBAR = DATA_SWIPER + "-scrollbar";

const DATA_ATTR_IMAGE_GALLERY = "data-image-gallery";

const CLASS_WRAPPER = "image-gallery__slides";
const CLASS_SLIDE = "image-gallery__slide";
const CLASS_SCROLLBAR = "image-gallery__scrollbar";

export class ImageGallery {
    private static instance: ImageGallery;

    public static getInstance(): ImageGallery {
        if (!ImageGallery.instance) {
            ImageGallery.instance = new ImageGallery();
        }

        return ImageGallery.instance;
    }

    private constructor() {
        const galleries = document.querySelectorAll(`[${DATA_ATTR_IMAGE_GALLERY}]`) as NodeListOf<HTMLElement>;

        galleries.forEach(gallery => {
            const container = getElementBySelector(`[${DATA_ATTR_SWIPER_CONTAINER}]`, gallery) as HTMLElement;
            const nextEl = getElementBySelector(`[${DATA_ATTR_SWIPER_NAV_NEXT}]`, gallery) as HTMLElement;
            const prevEl = getElementBySelector(`[${DATA_ATTR_SWIPER_NAV_PREV}]`, gallery) as HTMLElement;
            const scrollbarEl = getElementBySelector(`[${DATA_ATTR_SWIPER_SCROLLBAR}]`, gallery) as HTMLElement;

            const swiper = new Swiper(container, {
                init: false,
                slidesPerView: "auto",
                centerInsufficientSlides: true,
                freeMode: true,
                spaceBetween: 8,

                wrapperClass: CLASS_WRAPPER,
                slideClass: CLASS_SLIDE,

                scrollbar: {
                    el: scrollbarEl,
                    draggable: true,
                    dragClass: CLASS_SCROLLBAR,
                    snapOnRelease: false,
                },

                navigation: {
                    nextEl,
                    prevEl,
                    disabledClass: CLASS_IS_DISABLED,
                },
            });

            swiper.init();
        });
    }
}
